import '../site-common/index.css'
import { createRoot } from 'react-dom/client'
import { DevApp, RootApp } from 'Components'

const container = document.getElementById('app')
const app = createRoot(container)
app.render(
  <RootApp>
    <DevApp />
  </RootApp>
)
